body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  footer {
   margin-top: 400px;
  }




