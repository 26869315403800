/* custom non-Tailwind CSS */

@media (max-width: 576px) {
    .content {
        padding-top: 51px;
    }
}

@media (min-width: 577px) {
  .pt-scroll {
    padding-top: 51px;
  }

  .nav-sticky {
    position: fixed!important;
    min-width: 100%;
    top: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    transition: all .25s ease-in;
    z-index: 1;
  }
}

/* HAMBURGER MENU */

.hamburger {
  cursor: pointer;
  width: 60px;
  height: 60px;
  transition: all 0.25s;
}

.hamburger__top-bun,
.hamburger__bottom-bun,
.hamburger__middle-bun {
  content: '';
  position: absolute;
  width: 48px;
  height: 4px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger:hover [class*="-bun"] {
  background: #333;
}

.hamburger__top-bun {
  transform: translateY(-12px);
}

.hamburger__middle-bun {
    transform: translateY(0px);
}

.hamburger__bottom-bun {
  transform: translateY(12px);
}

.open .hamburger__top-bun {
  transform:
    rotate(45deg)
    translateY(0px);
}

.open .hamburger__middle-bun {
  opacity: 0;
}

.open .hamburger__bottom-bun {
  transform:
    rotate(-45deg)
    translateY(0px);
}

.nav-link {
  max-width: 140px;
  text-align: center;
}

.nav-link .active {
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
}
